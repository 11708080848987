<!-- 关于我们 -->
<template>
    <div class="about-us">
        <div class="banner">
            <div class="info">
                <h2>源于客户<span>需求</span>，优于客户<span>期望</span></h2>
                <p>
                    致力于为城镇、园区、景区、公建项目、综合体、高校、等各类不动产持有方、物业管理企业提供最优质的项目与服务
                </p>
            </div>
        </div>
        <div class="content">
            <section id="introduce">
                <h2>公司介绍</h2>
                <div class="introduce-content" v-for="(item, index) in introduceList" :key="index">
                    <img :src="item.img" alt="" />
                    <div class="info">
                        <h4>{{ item.h4 }}</h4>
                        <p>{{ item.details }}</p>
                    </div>
                </div>
            </section>
            <!-- 数量展示 -->
            <section id="numbers">
                <h2>凭借易用的产品和优质的服务，先后服务于众多客户</h2>
                <div class="container">
                    <div class="item" v-for="(item, index) in numbersList" :key="index">
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.details }}</p>
                    </div>
                </div>
            </section>
            <!-- 软件专利 -->
            <section class="software-patents">
                <h2>软件专利</h2>
                <div class="carousel">
									
									<el-carousel arrow="never">
									    <el-carousel-item class="el-carousel-item" v-for="(item, index) in softwareList" :key="index">
												<div class="item-box" v-for="(citem, cindex) in item.children" :key="cindex">
													<div class="image-box">
														<el-image :src="citem.img" :preview-src-list="[...citem.img]"></el-image>
													</div>
													<el-tooltip effect="dark" :content="citem.details" placement="top">
															<p>{{ citem.details }}</p>
													</el-tooltip>
													<h4>{{ citem.title }}</h4>
												</div>
									    </el-carousel-item>
									</el-carousel>
									
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            introduceList: [
                {
                    isOpen: false,
                    img: require("../../assets/aboutUs/list1.png"),
                    h4: "上海平正云科技有限公司",
                    details:
                        "上海平正云科技有限公司成立于2016年，是一家基于大数据的第三方评估云平台系统的专业提供商和分析工具服务商，荣获2019年度杨浦区“专精特新”企业称号。",
                },
                {
                    isOpen: false,
                    img: require("../../assets/aboutUs/list2.png"),
                    h4: "核心团队高效工作",
                    details:
                        "核心团队长期从事企业管理、项目管理、后勤管理、工程管理、房地产管理、城市管理和区域经济等方面的理论研究和实务工作。",
                },
                {
                    isOpen: false,
                    img: require("../../assets/aboutUs/list3.png"),
                    h4: "底蕴深厚业务广泛氛围简单",
                    details: `公司目前已拥有14项软件著作权专利，致力于为城镇、园区、景区、公建项目、综合体、高校、等各类不动产持有方、物业管理企业提供系统化整体性解决方案。
                    产品涵盖管理后台、app、小程序、大数据衍生产品、数据可视化部署、物联网相关设备，从软件至硬件全方位集成服务，业务范围集中于长三角区域。创造年轻化活力有创意的小而美技术团队，产研人员为主，氛围简单。`,
                },
            ], //公司介绍
            numbersList: [
                {
                    title: "19+",
                    details: "软件专利",
                },
                {
                    title: "20+",
                    details: "合作伙伴",
                },
                {
                    title: "16+",
                    details: "涉及行业",
                },
                {
                    title: "50+",
                    details: "用户量(万)",
                },
            ], //数量展示
            softwareList: [
							{
								children: [{
										img: require("../../assets/aboutUs/1-1.png"),
										title: "软著证书",
										details: "平正云大数据分析报告信息化软件",
									}, {
									    img: require("../../assets/aboutUs/1-2.png"),
									    title: "软著证书",
									    details: "平正云定向评估数据可视化采集软件",
									}, {
									    img: require("../../assets/aboutUs/1-3.png"),
									    title: "软著证书",
									    details: "平正云企业直聘平台后台管理软件",
									}, {
									    img: require("../../assets/aboutUs/1-4.png"),
									    title: "软著证书",
									    details: "平正云千村调查实时运营监控中心软件",
									}
								],
							}, {
								children: [{
									    img: require("../../assets/aboutUs/2-1.png"),
									    title: "软著证书",
									    details: "平正云千村调查数据采集微信客户端软件",
									}, {
									    img: require("../../assets/aboutUs/2-2.png"),
									    title: "软著证书",
									    details: "平正云社会治理标准化基础信息管理软件",
									}, {
									    img: require("../../assets/aboutUs/2-3.png"),
									    title: "软著证书",
									    details: "平正云社会治理标准化智慧作业微信客户端软件",
									}, {
									    img: require("../../assets/aboutUs/2-4.png"),
									    title: "软著证书",
									    details: "平正云信息化评估数据分析软件",
									}
								]
							}, {
								children: [{
									    img: require("../../assets/aboutUs/3-1.png"),
									    title: "软著证书",
									    details: "平正云指标管理架构信息化管理软件",
									}, {
									    img: require("../../assets/aboutUs/3-2.png"),
									    title: "软著证书",
									    details: "第三方评估整改管理信息化微信端软件",
									}, {
									    img: require("../../assets/aboutUs/3-3.png"),
									    title: "软著证书",
									    details: "高校后勤标准信息化维修服务微信端管理软件",
									}, {
									    img: require("../../assets/aboutUs/3-4.png"),
									    title: "软著证书",
									    details: "平正云基于企业微信的巡查管理平台软件",
									}
								]
							}, {
								children: [{
									    img: require("../../assets/aboutUs/4-1.png"),
									    title: "软著证书",
									    details: "平正云企业内部推荐招聘软件",
									}, {
									    img: require("../../assets/aboutUs/4-2.png"),
									    title: "软著证书",
									    details: "平正云社会调查数据采集管理软件",
									}, {
									    img: require("../../assets/aboutUs/4-3.png"),
									    title: "软著证书",
									    details: "平正云社会治理标准化智慧作业软件",
									}, {
									    img: require("../../assets/aboutUs/4-4.png"),
									    title: "软著证书",
									    details: "平正云社会治理可视化软件",
									}
								]
							}, {
								children: [
									{
									    img: require("../../assets/aboutUs/5-1.png"),
									    title: "软著证书",
									    details: "项目管理第三方评估平台系统软件",
									}, {
									    img: require("../../assets/aboutUs/5-2.png"),
									    title: "软著证书",
									    details: "平正云环卫一体化管理系统",
									}, {
									    img: require("../../assets/aboutUs/5-3.png"),
									    title: "软著证书",
									    details: "平正云城市管家管理系统",
									}
								]
							}
            ], //软件专利
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "aboutUs_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "aboutUs_mobile.scss";
}
</style>
